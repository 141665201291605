// Dependencies
import styled from 'styled-components';

const CookieNoticeStyles = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s opacity;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: not-allowed;

  .cookie-notice {
    position: absolute;
    padding: 1.5rem;
    background-color: var(--color-beige);
    box-shadow: 0.313rem 0.313rem 0.625rem 0.313rem rgba(0, 0, 0, 5%);
    cursor: auto;

    .heading {
      font-size: 1.1rem;
      font-weight: var(--font-weight-medium);
      text-align: center;
    }

    .text {
      font-size: 1.1rem;
      line-height: 1.5;
      text-align: center;

      .learn-more {
        display: inline-block;
        margin: 0.5rem 0 0;
        font-size: 1.1rem;
        line-height: 1.4;
      }
    }

    .btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .btn {
        margin: 1.5rem 0 0;

        &.decline {
          margin-right: 1rem;
        }
      }
    }
  }

  &.loaded {
    opacity: 1;
    pointer-events: initial;
  }

  &.accepted,
  &.declined {
    opacity: 0;
    pointer-events: none;
  }

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;

    .cookie-notice {
      position: static;
      margin: 0 0 0.938rem 1.25rem;
      padding: 2rem;
      max-width: 36rem;

      .heading {
        font-size: 1.25rem;
      }

      .text {
        text-align: left;
      }

      .btn-wrapper {
        margin-top: 0.5rem;
      }
    }
  }
`;

export default CookieNoticeStyles;
